let API_URL;
let SHOPFRONT_URL;
let FUNERALPLANNER_URL;
let PROFILE_URL;

const host = window && window.location && window.location.hostname;


if(host === "localhost"){
     //API_URL = "https://localhost:9260";
     //API_URL = "https://api-dev.getup.metropolitan.co.za";
     API_URL = "https://api.getup.metropolitan.co.za";
     SHOPFRONT_URL = "https://develop.d2omnxwob7neqd.amplifyapp.com/";
     PROFILE_URL = "https://www.mymetgetup-dev.co.za/";
     FUNERALPLANNER_URL = "https://www.funeralplannermetgetup-dev.co.za/";


}
else if(host === "www.funeralcovermetgetup.co.za"){
     API_URL = "https://api.getup.metropolitan.co.za";
     SHOPFRONT_URL = "https://www.metgetup.co.za/"
     PROFILE_URL = "https://www.mymetgetup.co.za/";
     FUNERALPLANNER_URL = "https://www.funeralplannermetgetup.co.za/";
}
else
{
     API_URL = "https://api-dev.getup.metropolitan.co.za";
     SHOPFRONT_URL = "https://develop.d2omnxwob7neqd.amplifyapp.com/";
     PROFILE_URL = "https://www.mymetgetup-dev.co.za/";
     FUNERALPLANNER_URL = "https://www.funeralplannermetgetup-dev.co.za/";
}

export const API_CONFIG = {API_URL: API_URL, SHOPFRONT_URL: SHOPFRONT_URL, FUNERALPLANNER_URL: FUNERALPLANNER_URL, PROFILE_URL: PROFILE_URL};

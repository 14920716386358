import axios from 'axios';
import {API_CONFIG} from "../config/api-config";
import moment from "moment";

import relationList from "../assets/data/relationList.json";
import UtilService from "./UtilService";
import lifeTypeList from "../assets/data/lifeTypeList.json";
import genderList from "../assets/data/genderList.json";

const host = window && window.location && window.location.hostname;
let mfp_username;
let mfp_password;

let gateway_username = "getup";
let gateway_password;

let paymentgateway_username = "paymentgateway";
let paymentgateway_password;



let getupclientUsername = 'getupclient';
let getupclientPassword;

if (host === "localhost") {
    /*mfp_username = 'digitalUser';
    mfp_password = "_OK!_46&MW/rG\\~xW{_G";
    getupclientPassword = "._a&!mS<CGF9Dz;@IqiK";
    gateway_password = "4golKs(/:!3)n\\txNFUQ";
    paymentgateway_password = "._a&!mS<CGF9Dz;@IqiK";*/
    mfp_username = 'digitalUser';
    mfp_password = "wpfd$Q0gO#g1T)YB\"E+G";
    getupclientPassword = "-z?IXQav,%Ei|G=.50Wg";
    gateway_password = "-<TO){k9x$5&^4Wq=@n;";
    paymentgateway_password = "-z?IXQav,%Ei|G=.50Wg";

} else if (host === "www.funeralcovermetgetup.co.za") {
    mfp_username = 'digitalUser';
    mfp_password = "wpfd$Q0gO#g1T)YB\"E+G";
    getupclientPassword = "-z?IXQav,%Ei|G=.50Wg";
    gateway_password = "-<TO){k9x$5&^4Wq=@n;";
    paymentgateway_password = "-z?IXQav,%Ei|G=.50Wg";
} else {
    mfp_username = 'digitalUser';
    mfp_password = "_OK!_46&MW/rG\\~xW{_G";
    getupclientPassword = "._a&!mS<CGF9Dz;@IqiK";
    gateway_password = "4golKs(/:!3)n\\txNFUQ";
    paymentgateway_password = "._a&!mS<CGF9Dz;@IqiK";
}

const mfp_token = mfp_username + ":" + mfp_password;
const mfp_encodedToken = Buffer.from(mfp_token).toString('base64');

const gateway_encodedToken = Buffer.from(gateway_username.concat(":").concat(gateway_password)).toString('base64');
const paymentgateway_encodedToken = Buffer.from(paymentgateway_username.concat(":").concat(paymentgateway_password)).toString('base64');
const getupclientEncodedToken = Buffer.from(getupclientUsername.concat(":").concat(getupclientPassword)).toString('base64');

const mfp_headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Authorization": "Basic " + mfp_encodedToken
};

const gateway_headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Authorization": "Basic " + gateway_encodedToken
};

const getupclientHeaders = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Authorization": "Basic " + getupclientEncodedToken
};

const paymentgateway_headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Authorization": "Basic " + paymentgateway_encodedToken
};

const API_URL_GET_QUOTE = API_CONFIG.API_URL.concat('/mfpwebproduct/createNewMinimalBusinessQuote');
const API_URL_ISSUE_POLICY = API_CONFIG.API_URL.concat('/gu-gateway/mfp/submitNewBusinessClient');
// const API_URL_ISSUE_POLICY = API_CONFIG.API_URL.concat('/mfpwebproduct/submitNewBusinessClient');
const API_URL_GET_PAYREF = API_CONFIG.API_URL.concat('/paymentgateway/payref/save');
const API_URL_CHECK_EXISTING_POLICY = API_CONFIG.API_URL.concat('/cvpproduct/policy/enrol/hasExistingFuneralPolicy/');
/*const API_URL_VALIDATE_BANK_DETAILS = API_CONFIG.API_URL.concat('/cvpproduct/policy/enrol/validateBankDetails');*/
const META_DATA_TRACKING_URL = API_CONFIG.API_URL.concat('/mfpwebproduct/trackmetadata');
const API_URL_GET_META_DATA = API_CONFIG.API_URL.concat('/mfpwebproduct/getMetaDataForClient');
/*const API_URL_EVENT_TRACKING = API_CONFIG.API_URL.concat('/atom-reporting/upload/bot/event/tracking');*/
const API_URL_SAVE_QUOTE = API_CONFIG.API_URL.concat('/mfpwebproduct/sendSaveQuoteSMS');
const API_URL_CREATE_ZENDESK_CHAT = API_CONFIG.API_URL.concat('/mfpwebproduct/createZendeskChat');
const API_URL_CREATE_ZENDESK_VALIDATION_TICKET = API_CONFIG.API_URL.concat('/mfpwebproduct/createZendeskValidationTicket');

const API_URL_CREATE_KNOWN_CONSUMER = API_CONFIG.API_URL.concat('/getupclient/KnownConsumer/createKnownConsumer');
const API_URL_UPDATE_KNOWN_CONSUMER = API_CONFIG.API_URL.concat('/getupclient/KnownConsumer/updateKnownConsumer');
const API_URL_CREATE_OTP_TOKEN = API_CONFIG.API_URL.concat('/getupclient/KnownConsumer/createotpToken');
const API_URL_VALIDATE_OTP_TOKEN = API_CONFIG.API_URL.concat('/getupclient/KnownConsumer/validateotpToken');
const API_URL_UPDATE_KNOWN_CONSUMER_CONSENT = API_CONFIG.API_URL.concat('/getupclient/KnownConsumer/updateKnownConsumerConsent');
const API_URL_CREATE_KNOWN_CONSUMER_CONSENT = API_CONFIG.API_URL.concat('/getupclient/KnownConsumer/createMarketingConsent');
const API_URL_GET_KNOWN_CONSUMER_CONSENT = API_CONFIG.API_URL.concat('/getupclient/KnownConsumer/fetchKnownConsumerConsent');
const API_URL_GET_GETUPCLIENT = API_CONFIG.API_URL.concat('/getupclient/details/findGetupClient/');

/*const API_URL_VALIDATE_BANKING_DETAILS = API_CONFIG.API_URL.concat('/gu-gateway/validation/validateBankingDetails');
const API_URL_VALIDATE_CLIENT_DETAILS = API_CONFIG.API_URL.concat('/gu-gateway/validation/validateClientInfo');*/
const API_URL_VALIDATE_BANKING_DETAILS = API_CONFIG.API_URL.concat('/mfpwebproduct/validateBankingDetails');
const API_URL_VALIDATE_CLIENT_DETAILS = API_CONFIG.API_URL.concat('/mfpwebproduct/validateClientInfo');

const getDOBFromIdNumber = (idNumber) => {
    let dateOfBirth;
    let datePart = idNumber.toString().substring(0, 6);          //yymmdd

    let selectedYear = datePart.substring(0, 2);  //yy
    let selectedMonth = parseInt(datePart.substring(2, 4)) - 1; //mm
    let selectedDay = datePart.substring(4, 6);   //dd

    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();  //yyyy
    /* let currentMonth = currentDate.getMonth() + 1;//mm
     let currentDay = currentDate.getDate();       //dd*/

    let CENTURY = 100;

    let currentCentury = parseInt(currentYear.toString().substring(0, 2));//YY
    let yearsPassedInCurrentCentury = parseInt(currentYear.toString().substring(2, 4));//YY{YY}
    let previousCentury = (currentYear - (CENTURY + yearsPassedInCurrentCentury));

    //if born in current century
    if (yearsPassedInCurrentCentury >= parseInt(selectedYear)) {
        dateOfBirth = new Date(currentCentury + selectedYear, selectedMonth, selectedDay);
    } else {
        //if born in previous century
        selectedYear = previousCentury + parseInt(selectedYear);
        dateOfBirth = new Date(selectedYear.toString(), selectedMonth, selectedDay);
    }
    return dateOfBirth;

}

class APIService {

    GetPerson = (idnumber) => {
        return axios.get(API_URL_GET_GETUPCLIENT +idnumber, { headers: getupclientHeaders})
    }

    GetPayRef = (values, getupclientnumber) => {
        let request =
        {
            "planType": "GetUp MFP",
            "getupClientNumber": getupclientnumber,
            "paymentPartner": "Pay@",
            "idNumber": values.root_state.mainMember.idNumber,
            "policyNum": values.root_state.policyNumber
        }

        return axios.post(API_URL_GET_PAYREF, request,{headers: paymentgateway_headers})
    }

    ValidateClientInfo = (values) => {
        return axios.get(API_URL_VALIDATE_CLIENT_DETAILS, {data: {}, headers: mfp_headers, params: {"firstName": values.root_state.mainMember.firstName, "lastName": values.root_state.mainMember.lastName, "idNumber": values.root_state.mainMember.idNumber, "subChannel": values.root_state.additionalInfo.subChannel}})
    }

    ValidateBankingDetailsCPB = (values) => {
        return axios.get(API_URL_VALIDATE_BANKING_DETAILS, {data: {}, headers: mfp_headers, params: {"bankName": values.root_state.bankDetails.bank, "accountNumber": values.root_state.bankDetails.accountNumber, "accountType": values.root_state.bankDetails.accountType, "firstName": values.root_state.mainMember.firstName, "lastName": values.root_state.mainMember.lastName, "idNumber": values.root_state.mainMember.idNumber,"subChannel": values.root_state.additionalInfo.subChannel}})
    }



    SaveQuote = (sessionId) => {
        return axios.get(API_URL_SAVE_QUOTE, {data: {}, headers: mfp_headers, params: {"sessionId": sessionId}})
    }

    CreateZendeskChat = (sessionId, type) => {
        return axios.get(API_URL_CREATE_ZENDESK_CHAT, {data: {}, headers: mfp_headers, params: {"sessionId": sessionId, "type": type}})
    }

    CreateZendeskValidationTicket = (sessionId, type) => {
        return axios.get(API_URL_CREATE_ZENDESK_VALIDATION_TICKET, {data: {}, headers: mfp_headers, params: {"sessionId": sessionId, "type": type}})
    }

    CreateKnownConsumer = (values) => {
        let request = {
            "first_name": values.root_state.mainMember.firstName,
            "surname": values.root_state.mainMember.lastName,
            "initials": values.root_state.mainMember.initials,
            "originating_source": "Met GetUp Website - MFP",
            "knownConsumerContactDto": []
        }
        if ((values.root_state.mainMember.idNumber !== null) && values.root_state.mainMember.idNumber.length > 0){
            request.id_number = values.root_state.mainMember.idNumber;
            request.id_type = "National ID";
        }

        if ((values.root_state.mainMember.dateOfBirth !== null) && values.root_state.mainMember.dateOfBirth.length > 0) {
            request.date_of_birth = values.root_state.mainMember.dateOfBirth;
        }

        if ((values.root_state.mainMember.title !== null) && values.root_state.mainMember.title.length > 0) {
            request.title = values.root_state.mainMember.title;
        }

        if ((values.root_state.mainMember.gender !== null) && values.root_state.mainMember.gender.length > 0) {
            request.gender = (UtilService.findByAttr(genderList, "value", values.root_state.mainMember.gender) !== -1) ? genderList[UtilService.findByAttr(genderList, "value", values.root_state.mainMember.gender)].display : values.root_state.mainMember.gender;
        }
        if ((values.root_state.mainMember.maritalStatus !== null) && values.root_state.mainMember.maritalStatus.length > 0) {
            request.marital_status = UtilService.capitiliseEachWord(values.root_state.mainMember.maritalStatus);
        }

        if ((values.root_state.mainMember.cellphone !== null) && (values.root_state.mainMember.cellphone.length > 0)) {
            let contactDetail = {
                "contact_type": "Cellphone",
                "contact_value": values.root_state.mainMember.cellphone
            };
            request.knownConsumerContactDto.push(contactDetail)
        }
        if ((values.root_state.mainMember.email !== null) && (values.root_state.mainMember.email.length > 0)) {
            let contactDetail = {
                "contact_type": "Email Address",

                "contact_value": values.root_state.mainMember.email
            };
            request.knownConsumerContactDto.push(contactDetail)
        }
        return axios.post(API_URL_CREATE_KNOWN_CONSUMER, request,{headers: getupclientHeaders})
    }

    UpdateKnownConsumer = (values) => {
        let request = {
            "known_consumer_number": values.root_state.mainMember.knownConsumerNumber,

            "first_name": values.root_state.mainMember.firstName,
            "surname": values.root_state.mainMember.lastName,
            "initials": values.root_state.mainMember.initials,
            "originating_source": "Met GetUp Website - MFP",
            "knownConsumerContactDto": []
        }

        if ((values.root_state.mainMember.dateOfBirth !== null) && values.root_state.mainMember.dateOfBirth.length > 0) {
            request.date_of_birth = values.root_state.mainMember.dateOfBirth;
        }

        if ((values.root_state.mainMember.title !== null) && values.root_state.mainMember.title.length > 0) {
            request.title = values.root_state.mainMember.title;
        }

        if ((values.root_state.mainMember.gender !== null) && values.root_state.mainMember.gender.length > 0) {
            request.gender = (UtilService.findByAttr(genderList, "value", values.root_state.mainMember.gender) !== -1) ? genderList[UtilService.findByAttr(genderList, "value", values.root_state.mainMember.gender)].display : values.root_state.mainMember.gender;
        }
        if ((values.root_state.mainMember.maritalStatus !== null) && values.root_state.mainMember.maritalStatus.length > 0) {
            request.marital_status = UtilService.capitiliseEachWord(values.root_state.mainMember.maritalStatus);
        }

        if ((values.root_state.mainMember.idNumber !== null) && (values.root_state.mainMember.idNumber.length > 0)){
            request.id_number = values.root_state.mainMember.idNumber
            request.id_type = "National ID"
        }


        if ((values.root_state.mainMember.cellphone !== null) && (values.root_state.mainMember.cellphone.length > 0)) {
            let contactDetail = {
                "contact_type": "Cellphone",
                "contact_value": values.root_state.mainMember.cellphone
            };

            request.knownConsumerContactDto.push(contactDetail)

        }
        if ((values.root_state.mainMember.email !== null) && (values.root_state.mainMember.email.length > 0)) {
            let contactDetail = {
                "contact_type": "Email Address",
                "contact_value": values.root_state.mainMember.email
            };
            request.knownConsumerContactDto.push(contactDetail)
        }
        return axios.post(API_URL_UPDATE_KNOWN_CONSUMER, request,{headers: getupclientHeaders})
    }

    CreateOTP = (values) => {
        let request = {
            "known_consumer_number": values.root_state.mainMember.knownConsumerNumber,
            "knownConsumerContactDto": []
        }

        /*  let cellIndex = 1;
          let emailIndex = 1;*/
        if ((values.root_state.mainMember.cellphone !== null) && (values.root_state.mainMember.cellphone.length > 0)) {
            let contactDetail = {
                "contact_type": "Cellphone",

                "contact_value": values.root_state.mainMember.cellphone
            };
            /* cellIndex++;*/
            request.knownConsumerContactDto.push(contactDetail)

        }
        /*if ((values.root_state.mainMember.email !== null) && (values.root_state.mainMember.email.length > 0)) {
            let contactDetail = {
                "contact_type": "Email Address",

                "contact_value": values.root_state.mainMember.email
            };
            emailIndex++;
            request.knownConsumerContactDto.push(contactDetail)
        }*/
        return axios.post(API_URL_CREATE_OTP_TOKEN, request,{headers: getupclientHeaders})
    }

    VerifyOTP = (values) => {
        let request = {
            "otp": values.app_state.enteredOTP,
            "known_consumer_number": values.root_state.mainMember.knownConsumerNumber
        }
        return axios.post(API_URL_VALIDATE_OTP_TOKEN,request, {headers: getupclientHeaders})
    }

    GetKnownConsumerConsent = (values) => {
        let request = {
            "known_consumer_number": values.root_state.mainMember.knownConsumerNumber
        }
        return axios.get(API_URL_GET_KNOWN_CONSUMER_CONSENT, {data: {}, headers: getupclientHeaders, params: {"knownConsumerNumber": values.root_state.mainMember.knownConsumerNumber}})
    }

    CreateKnownConsumerConsent = (consentType, values) => {
        let request = {

            "known_consumer_number": values.root_state.mainMember.knownConsumerNumber,
            "consentType": consentType
        }
        switch (consentType){
            case 'Marketing Research':
                request.optedIn = values.root_state.contactMe;
            case 'New Solutions':
                request.optedIn = values.root_state.contactMe;
            case 'Share Info Internally':
                request.optedIn = values.root_state.shareMyData;
            case 'Contact via Whatsapp':
                request.optedIn = values.root_state.whatsappConsent;
        }

        return axios.post(API_URL_CREATE_KNOWN_CONSUMER_CONSENT, request,{headers: getupclientHeaders})
    }

    UpdateKnownConsumerConsent = (consentType, values) => {
        let request = {

            "known_consumer_number": values.root_state.mainMember.knownConsumerNumber,
            "consentType": consentType
        }

        switch (consentType){
            case 'Marketing Research':
                request.optedIn = values.root_state.contactMe;
            case 'New Solutions':
                request.optedIn = values.root_state.contactMe;
            case 'Share Info Internally':
                request.optedIn = values.root_state.shareMyData;
            case 'Contact via Whatsapp':
                request.optedIn = values.root_state.whatsappConsent;
        }

        return axios.post(API_URL_UPDATE_KNOWN_CONSUMER_CONSENT, request,{headers: getupclientHeaders})
    }


    IssuePolicy = (values) => {
       const paymentType=()=>{
           let payType = "";

            if(values.root_state.paymentMethod === "Debit Order")
            {
                payType = "Debit Order";
            }else {

                    switch (values.app_state.flexiblePaymentOption) {
                        case 'eft':
                            payType = "EFT";
                            break;
                        case 'cash':
                            payType = "Cash";
                            break;
                        case 'creditCard':
                            payType = "Cash";
                            break;
                        case 'smartPhoneApps':
                            payType = "Direct Debit";
                            break;
                        case 'digitalMobile':
                            payType = "EFT";
                            break;
                        case 'ussd':
                            payType = "Cash";
                            break;

                        default:
                            payType = "Cash";
                            break;
                    }
            }
                return payType;
        }

        const deductionDay=(date)=> {
            let day = "";

            if (values.root_state.paymentMethod === "Debit Order") {
                day = date;
            } else {
                day = "01"
            }

            return day;
        }

        let request = {
            "getupPaymentType": paymentType(),
            "adRef": values.root_state.additionalInfo.adId,
            "source": values.root_state.additionalInfo.ad_publish_platform,
            "channel": values.root_state.additionalInfo.channel,
            "subChannel": values.root_state.additionalInfo.subChannel,
            "quoteNumber": values.root_state.quotePackageId,
            "firstname": values.root_state.mainMember.firstName,
            "faname": "Digital Direct",
            "lastname": values.root_state.mainMember.lastName,
            "initials": values.root_state.mainMember.initials,
            "dob": moment(getDOBFromIdNumber(values.root_state.mainMember.idNumber)).format('DD-MM-YYYY'),
            "pholderID": values.root_state.mainMember.idNumber,
            "gender": values.root_state.mainMember.gender,
            "title": values.root_state.mainMember.title,
            "phoneNo": values.root_state.mainMember.cellphone,
            "email": values.root_state.mainMember.email,
            "coverAmount": values.root_state.mainMember.coverAmount,
            "relationship": "main",
            "maritalStatus": values.root_state.mainMember.maritalStatus,
            "quotePackageId": values.root_state.quotePackageId,
            "mainMember": {
                "name": values.root_state.mainMember.firstName,
                "surname": values.root_state.mainMember.lastName,
                "benefitType": (UtilService.findByAttr(lifeTypeList, "lifeType", values.root_state.mainMember.lifeType) !== -1) ? lifeTypeList[UtilService.findByAttr(lifeTypeList, "lifeType", values.root_state.mainMember.lifeType)].mfpLifeType : values.root_state.mainMember.lifeType,
                "coverMain": values.root_state.mainMember.coverAmount,
                "coverMainPremium": values.root_state.mainMember.premium,
                "dob": moment(getDOBFromIdNumber(values.root_state.mainMember.idNumber)).format('DD-MM-YYYY'),
            },
            "replaceInsuranceYouDiscontinuedInThePast4Months": values.fusion === true ? "Yes" : "No",
            "replacementPolicyName": (values.root_state.replacements.length > 0?values.root_state.replacements[0].replaceCompany:""),
            "replacementPolicyNumber": (values.root_state.replacements.length > 0?values.root_state.replacements[0].replacePolicyNumber:""),
            "reasonForDO": "I prefer to pay via bank DO",
            "accountHolderInitials": values.root_state.mainMember.initials,


            "salaryDate": values.root_state.billingDay,

            "deductionDate": deductionDay(values.root_state.billingDay),

            "accountHolderSurname": values.root_state.mainMember.lastName,
            "bank": values.root_state.bankDetails.bank,
            "branchCode": values.root_state.bankDetails.branchCode,
            "accountType": values.root_state.bankDetails.accountType,
            "accountNumber": values.root_state.bankDetails.accountNumber,
            "marketingConsent1": values.root_state.contactMe === true ? "Yes" : "No",
            "marketingConsent2": values.root_state.shareMyData === true ? "Yes" : "No",
            "youOrCloseFamilyAreNotAProminentInfluPerson": values.root_state.isPip === true ? "Yes" : "No",

            "commsReminderDay": values.root_state.paymentReminderDay

        }
        values.root_state.beneficiaries.forEach((beneficiary, index) => {
            request.benLname=  beneficiary.lastName;
            request.benFname = beneficiary.firstName;
            request.benType = lifeTypeList[UtilService.findByAttr(lifeTypeList,"lifeType",((UtilService.findByAttrBRel(relationList, "value", beneficiary.relation) !== -1) ? relationList[UtilService.findByAttrBRel(relationList, "value", beneficiary.relation)].lifeType : "MUEF"))].mfpLifeType;
            request.benRelation = beneficiary.relation;
            request.benCell = beneficiary.cellphone;
            request.benDOB = moment(beneficiary.dob).format('DD-MM-YYYY');
            request.benGender = beneficiary.gender.charAt(0).toUpperCase() ;

        });

        let temp_relMembers = [];
        values.root_state.additionalLives.forEach((item, index) => {
            let relMember = {};
            relMember.name = item.firstName;
            relMember.surname = item.lastName;
            relMember.gender = item.gender;
            relMember.benefitType =
            (UtilService.findByAttr(lifeTypeList, "lifeType", item.lifeType) !== -1) ? lifeTypeList[UtilService.findByAttr(lifeTypeList, "lifeType", item.lifeType)].mfpLifeType : item.lifeType;
            relMember.coverMember = item.coverAmount;
            relMember.covermemberPremium = item.premium;
            relMember.dob = moment(item.dateOfBirth).format('DD-MM-YYYY');
            relMember.relation = item.relation;
            temp_relMembers.push(relMember)
        });
        request.mainMember.relMember = temp_relMembers;

        let temp_replacement = [];
        if ((values.root_state.replacements.length > 0) && (values.app_state.fusion === true)){
            values.root_state.replacements.forEach((item, index) => {
                let replacement = {};
                replacement.replacementCompany = item.replaceCompany;
                replacement.policyNumber = item.replacePolicyNumber;
                replacement.completedWaitingPeriod = item.hasCompletedWaitingPeriod;
                temp_replacement.push(replacement);
            });
            request.replacements = temp_replacement;
        }

        return axios.post(API_URL_ISSUE_POLICY, request, {headers: gateway_headers});
    };

    GetQuote = (values) => {
        let request = {
            "inceptionDate": values.app_state.inceptionDate,
            "policyHolders": [
                {
                    "rolePlayerId": values.root_state.mainMember.rolePlayerId,
                    "percentage": 100
                }
            ],
            "benefits": [],
            "roleplayers": [
                {
                    "rolePlayerId": values.root_state.mainMember.rolePlayerId,
                    "clientNumber": values.root_state.mainMember.rolePlayerId,
                    "dateOfBirth": moment(values.root_state.mainMember.dateOfBirth).format('YYYY-MM-DD'),
                }
            ],
        }

        if ((values.root_state.mainMember.coverAmount !== null) ) {
            let mainMemberBenefit = {
                "benefitIdentifier": {
                    "benefitCode": (UtilService.findByAttr(lifeTypeList, "lifeType", values.root_state.mainMember.lifeType) !== -1) ? lifeTypeList[UtilService.findByAttr(lifeTypeList, "lifeType", values.root_state.mainMember.lifeType)].mfpLifeType : values.root_state.mainMember.lifeType,
                },
                "benefitAmount": values.root_state.mainMember.coverAmount,
                "coverGrowthPercentage": values.root_state.inflation,
                "premiumGrowthPercentage": values.root_state.inflation,
                "lives": [
                    {
                        "rolePlayerId": values.root_state.mainMember.rolePlayerId
                    }
                ]
            }
            request.benefits.push(mainMemberBenefit)
        }

        /*"cashBack": values.root_state.cashBack,
            "coverForLife": values.root_state.coverForLife,
            "inflation": values.root_state.inflation,

            "additionalInformation": {
            "channel": values.root_state.additionalInfo.channel,
                "subChannel": values.root_state.additionalInfo.subChannel,
                "externalReference": values.root_state.additionalInfo.externalReference,
                "adId": values.root_state.additionalInfo.adId,
                "ad_publish_platform": values.root_state.additionalInfo.ad_publish_platform,
                "leadId": values.root_state.additionalInfo.leadID,
                "leadProvider": values.root_state.additionalInfo.leadProvider
        },
        "mainMember": {
            "firstName": values.root_state.mainMember.firstName,
                "lastName": values.root_state.mainMember.lastName,
                "age": parseInt(values.root_state.mainMember.age, 10),
                "gender": values.root_state.mainMember.gender,
                "income": ((typeof values.root_state.mainMember.income === "undefined") || (values.root_state.mainMember.income === null) || ((typeof values.root_state.mainMember.income !== "undefined") && (values.root_state.mainMember.income.length === 0))) ? 0.00 : values.root_state.mainMember.income,
                "medicalAid": values.root_state.mainMember.medicalAid,
                "maritalStatus": values.root_state.mainMember.maritalStatus,
                "industryOfEmployment": values.root_state.mainMember.industryOfEmployment,
                "province": values.root_state.mainMember.province,
                "educationLevel": values.root_state.mainMember.educationLevel,
                "coverAmount": ((typeof values.root_state.mainMember.coverAmount === "undefined") || (values.root_state.mainMember.coverAmount === null) || ((typeof values.root_state.mainMember.coverAmount !== "undefined") && (values.root_state.mainMember.coverAmount === 0))) ? 0.00 : values.root_state.mainMember.coverAmount

        }*/

        let childBenefitIndex = -1;
        values.root_state.additionalLives.forEach((life, index) => {
            let additionalLife = {
                benefitIdentifier: {}
            };

            if (((life.lifeType === 'GUC') && (childBenefitIndex === -1)) || (life.lifeType !== 'GUC')) {
                additionalLife.benefitIdentifier.benefitCode = (UtilService.findByAttr(lifeTypeList, "lifeType", life.lifeType) !== -1) ? lifeTypeList[UtilService.findByAttr(lifeTypeList, "lifeType", life.lifeType)].mfpLifeType : life.lifeType;
                additionalLife.benefitAmount = life.coverAmount;
                additionalLife.coverTermWholeLife = ((life.lifeType === 'GUCL') ? true : false);
                additionalLife.coverGrowthPercentage = values.root_state.inflation
                additionalLife.premiumGrowthPercentage = values.root_state.inflation
                if (life.lifeType !== 'GUC') {
                    additionalLife.lives = [{"rolePlayerId": life.rolePlayerId}]
                    request.benefits.push(additionalLife);
                } else {
                    additionalLife.lives = [];
                    request.benefits.push(additionalLife);
                    childBenefitIndex = request.benefits.length - 1;
                }


            }
            if (life.lifeType === 'GUC') {
                if (childBenefitIndex !== -1) {

                    request.benefits[childBenefitIndex].lives.push({rolePlayerId: life.rolePlayerId})
                }
            }


            request.roleplayers.push({
                "rolePlayerId": life.rolePlayerId,
                "clientNumber": life.rolePlayerId,
                "dateOfBirth": life.dateOfBirth
            })

        });
        // if ((typeof values.root_state.quotePackageId !== "undefined") && (values.root_state.quotePackageId !== null) && (values.root_state.quotePackageId.length > 0)) {
        //     request.quoteNumber = values.root_state.quotePackageId;
        // }

        return axios.post(API_URL_GET_QUOTE, request, {headers: mfp_headers});
    };




    GetMetaData = (REQUEST) => {
        return axios.get(API_URL_GET_META_DATA, {data: {}, headers: mfp_headers, params: REQUEST});
    };

  /*  ValidateBankingDetails = (request) => {


        return axios.post(API_URL_VALIDATE_BANK_DETAILS, request, {headers: mfp_headers});
    }*/


    GetBlogItems = () => {
        /* console.log("GET BLOG ITEMS");*/
        return axios.get("https://content.metgetup.co.za/wp-json/wp/v2/posts/");
    };

    MFPWebTrackmetadata = async (values) => {
        //console.log("Tracking values: " + JSON.stringify(values))
        let track_AdditionalLives = [];
        let track_Beneficiaries = [];
        let track_Replacements = [];
        if ((Object.entries(values.root_state.replacements).length > 0) && (values.app_state.fusion === true)) {
            values.root_state.replacements.forEach((item, index) => {
                let replacement = {};
                replacement.replacement_company = item.replaceCompany;
                replacement.replacement_policy_number = item.replacePolicyNumber;
                replacement.completed_waiting_period = item.hasCompletedWaitingPeriod;

                track_Replacements.push(replacement)
            });
        }
        if (Object.entries(values.root_state.additionalLives).length > 0) {

            values.root_state.additionalLives.forEach((item, index) => {
                let additionalLife = {};
                additionalLife.uniqueLifeIdentifier = item.uniqueIdentifier;
                additionalLife.additionalLifeFirstName = item.firstName;
                additionalLife.additionalLifeSurname = item.lastName;
                additionalLife.additionalLifeAge = item.age;
                additionalLife.additionalLifeDateOfBirth = item.dateOfBirth;
                additionalLife.additionalLifeRelationship = item.relation;
                additionalLife.additionalLifeGender = item.gender;

                additionalLife.additionalLifeCoverAmount = item.coverAmount;

                additionalLife.additionalLifeDependentOnParents = item.dependentOnParents;
                additionalLife.additionalLifeDisability = item.disability;
                additionalLife.additionalLifeFullTimeStudent = item.fullTimeStudent;
                additionalLife.additionalLifePremium = item.premium;

                additionalLife.additionalLifeIsBiologicalOrAdoptedChild = item.isBiologicalOrLegallyAdoptedChild;
                additionalLife.additionalLifeLivingWithMainMember = item.livingWithMainMember;
                additionalLife.additionalLifeType = item.lifeType;
                track_AdditionalLives.push(additionalLife)
            });
        }
        if ((Object.entries(values.root_state.beneficiaries).length > 0)) {
            values.root_state.beneficiaries.forEach((item) => {
                let beneficiary = {};
                beneficiary.beneficiaryFirstName = item.firstName;
                beneficiary.beneficiarySurname = item.lastName;
                beneficiary.beneficiaryDateOfBirth = item.dob;

                beneficiary.beneficiaryRelationship = item.relation;
                beneficiary.beneficiaryCellphone = item.cellphone;
                beneficiary.beneficiaryPercentage = "100";
                if (!((item.firstName.length === 0) && (item.lastName.length === 0) && (item.cellphone.length === 0) && (item.dob === null) && (item.relation.length === 0))) {
                    track_Beneficiaries.push(beneficiary)
                }
                ;
            });
        }

        let body = {
            "sessionId": values.app_state.SESSION_ID,
            "quotePackageId": values.root_state.quotePackageId,
            "inflation": values.root_state.inflation,
            "channel": values.root_state.additionalInfo.channel,
            "subchannel": values.root_state.additionalInfo.subChannel,
            "externalReference": values.root_state.additionalInfo.externalReference,
            "ad_id": values.root_state.additionalInfo.adId,
            "ad_publish_platform": values.root_state.additionalInfo.ad_publish_platform,
            "totalPremiumForInsuredLives": values.root_state.totalPremiumForInsuredLives,
            "totalCoverForInsuredLives": values.root_state.totalCoverForInsuredLives,
            "poTitle": values.root_state.mainMember.title,
            "poInitials": values.root_state.mainMember.initials,
            "poFirstName": values.root_state.mainMember.firstName,
            "poSurname": values.root_state.mainMember.lastName,
            "poEmailAddress": values.root_state.mainMember.email,
            "poCellphone": values.root_state.mainMember.cellphone,
            "poCellphoneVerified": UtilService.findByAttr(values.root_state.mainMember.contactDetails, "contactValue", values.root_state.mainMember.cellphone) !== -1 ? values.root_state.mainMember.contactDetails[UtilService.findByAttr(values.root_state.mainMember.contactDetails, "contactValue", values.root_state.mainMember.cellphone)].validated : false,
            "poIdNumber": values.root_state.mainMember.idNumber,
            "poGender": values.root_state.mainMember.gender,
            "poAge": values.root_state.mainMember.age,
            "poDateOfBirth": values.root_state.mainMember.dateOfBirth,
            "poMaritalStatus": values.root_state.mainMember.maritalStatus,
            "poCoverAmount": values.root_state.mainMember.coverAmount,
            "poPremiumAmount": values.root_state.mainMember.premium,
            "paymentMethod": values.root_state.paymentMethod,
            "billingDay": values.root_state.billingDay,
            "bankName": values.root_state.bankDetails.bank,
            "accountType": values.root_state.bankDetails.accountType,
            "accountNumber": values.root_state.bankDetails.accountNumber,
            "branchCode": values.root_state.bankDetails.branchCode,
            "contactPreference": values.root_state.contactPreference,
            "preferredContactTime": values.root_state.preferredContactTime,
            "pip": (values.root_state.isPip === 'true'),
            "consentToContact": values.root_state.contactMe,
            "consentToShare": values.root_state.shareMyData,
            "acceptDisclaimer": values.root_state.acceptDisclaimer,
            "fusion": values.app_state.fusion,
            "policyNumber": values.root_state.policyNumber,
            "zendeskTicketNumber": values.root_state.zendeskTicketNumber,
            "leadID": values.root_state.additionalInfo.leadID,
            "leadProvider": values.root_state.additionalInfo.leadProvider,
            "currentStep": values.app_state.currentStep,
            "planType": values.app_state.planType,
            "webChatTicket": values.root_state.webChatTicket,
            "webChatTicketDate": values.root_state.webChatTicketDateRequested,
            "knownConsumerNumber": values.root_state.mainMember.knownConsumerNumber,
            "additionalLives": track_AdditionalLives,
            "beneficiaries": track_Beneficiaries,
            "pageHistories": [],
            "replacementPolicies": track_Replacements,
            "consentToWA": values.root_state.whatsappConsent
        }

        return await axios.post(META_DATA_TRACKING_URL, body, {headers: mfp_headers});
    };

   /* AddEventTracking = (values) => {
        let request = {
            "id": "",
            "uid": "",
            "psId": "",
            "trackingStatus": "",
            "productType": "atom",
            "engagementChannel": "web",
            "dataTimeStamp": moment().format(),
            "sessionId": values.root_state.sessionID
        }
        return axios.post(API_URL_EVENT_TRACKING, request, {headers: mfp_headers});
    }*/


}

export default new APIService();
